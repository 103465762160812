import _, { isArray, isNumber, isString } from "lodash"
import Immutable from 'seamless-immutable'



interface CommonHistoricWidgetParams {
  type: 'bar' | 'line'
  indicator?: string
  //key: string
  formula?: string
  formulaConfig?: string
  mapper?: (data: Object[], extra?: any) => BaseEntity[]
  grouped?: string
}

interface CommonWidgetParams {
  commonWidgets: CommonWidget,
  name: string,
  position: number[] | ((filters: FiltersUI) => number[])
  size: number[]
  criticalMomentId?: number
  criticalMomentInclude?: number[]
  geoLocationId?: number
  geoLocationInclude?: number[]
  logicalLocationId?: number
  logicalLocationInclude?: number[]
  key?: string,
  indicator?: string,
  url?: string,
  title?: string,
  subTitle?: string
  showSample?: boolean
  helpText?: string
  extraIndicatorConfigs?: { [key: string]: any },
  extraConfigs?: { [key: string]: any },
  extras?: { [key: string]: any }
  // historic
  historic?: CommonHistoricWidgetParams,
  render?: (filters: FiltersUI) => boolean
  mapper?: (data: Object[], extra?: any) => BaseEntity[]
  tooltipFormatter?: (params: any) => string
}

const getCriticalMomentInclude = (props: CommonWidgetParams) => {
  var criticalMomentInclude = props.criticalMomentInclude
    ? props.criticalMomentInclude
    : undefined

  if (props.criticalMomentId !== undefined) {
    criticalMomentInclude = criticalMomentInclude !== undefined
      ? criticalMomentInclude.concat([props.criticalMomentId])
      : [props.criticalMomentId]
  }

  return criticalMomentInclude
}

const getGeoLocationInclude = (props: CommonWidgetParams) => {
  var geoLocationInclude = props.geoLocationInclude
    ? props.geoLocationInclude
    : undefined

  if (props.geoLocationId !== undefined) {
    geoLocationInclude = geoLocationInclude !== undefined
      ? geoLocationInclude.concat([props.geoLocationId])
      : [props.geoLocationId]
  }

  return geoLocationInclude
}

const getLogicalLocationInclude = (props: CommonWidgetParams) => {
  var logicalLocationInclude = props.logicalLocationInclude
    ? props.logicalLocationInclude
    : undefined

  if (props.logicalLocationId !== undefined) {
    logicalLocationInclude = logicalLocationInclude !== undefined
      ? logicalLocationInclude.concat([props.logicalLocationId])
      : [props.logicalLocationId]
  }

  return logicalLocationInclude
}

export const getHistoricIndicatorConfig = (props: {
  historic: CommonHistoricWidgetParams,
  indicator: string,
  widgetConfig?: CommonWidgetParams
}): IndicatorConfig => {
  const {
    historic,
    indicator,
    widgetConfig
  } = props

  // Critical Moment
  var criticalMomentInclude = widgetConfig !== undefined
    && getCriticalMomentInclude(widgetConfig)

  // Geo Location
  var geoLocationInclude = widgetConfig !== undefined
    && getGeoLocationInclude(widgetConfig)

  // Logical Location
  var logicalLocationInclude = widgetConfig !== undefined
    && getLogicalLocationInclude(widgetConfig)

  const histInd = historic.indicator ?? indicator
  return {
    //key: historic.key,
    key: indicator + '-historic',
    indicator: histInd,
    //url: `indicators/${histInd}/values?formula=wavg`,
    url: `indicators/${histInd}/values`,
    historic: true,
    grouped: historic.grouped ?? 'formattedMonth',
    keyExtract: ['group', 'groupName', 'count', 'value'],
    singleton: true,
    label: 'groupName',
    value: 'value',
    dataType: 'list',
    extras: {
      criticalMomentInclude: criticalMomentInclude,
      geographicDistributions: geoLocationInclude,
      logicalDistributions: logicalLocationInclude,
      periods: 6,
      formula: historic.formula ?? "wavg",
      formulaConfig: historic.formulaConfig
    }
  }
}

export const getCommonWidget = (props: CommonWidgetParams): Widget => {
  const {
    name,
    commonWidgets,
    title,
    subTitle,
    position,
    size,
    render,
    showSample,
    key,
    indicator,
    mapper,
    tooltipFormatter,
    extraConfigs,
    extraIndicatorConfigs,
    url,
    extras,
    historic,
    helpText
  } = props

  //console.log('Obteniendo widget ' + name + 'para indicador ' + indicator)
  //console.log(props)
  //console.log(historic)
  const widget = commonWidgets[name]

  // Critical Moment
  var criticalMomentInclude = getCriticalMomentInclude(props)

  // Geo Location
  var geoLocationInclude = getGeoLocationInclude(props)

  // Logical Location
  var logicalLocationInclude = getLogicalLocationInclude(props)

  const widgetId = widget?.config?.id + '-'
    + widget.config?.type + '-'
    + indicator + '-'
    + (key !== undefined ? key + '-' : '')
    + (url !== undefined ? (url + '-') : '')
    + (criticalMomentInclude !== undefined && criticalMomentInclude?.length > 0
      ? criticalMomentInclude?.join('-') : '')
    + (geoLocationInclude !== undefined && geoLocationInclude?.length > 0
      ? geoLocationInclude?.join('-') : '')

  var indicators = widget.config !== undefined
    && widget.config?.indicators !== undefined
    && widget.config?.indicators.length > 0
    ? widget.config?.indicators?.map((ind: any, idx: number) => {
      const indicatorKey = widget.config?.indicators?.length === 1
        ? (key || ind.key)
        : (ind.key || key)
      return {
        ...ind,
        key: indicatorKey,
        indicator: indicator || ind.indicator,
        url: url || ind.url,
        ...extraIndicatorConfigs,
        extras: {
          ...ind.extras,
          ...extras,
          criticalMomentInclude: criticalMomentInclude,
          geographicDistributions: geoLocationInclude,
          logicalDistributions: logicalLocationInclude
        }
      }
    })
    : []

  if (historic !== undefined) {
    indicators = [
      ...indicators,
      getHistoricIndicatorConfig({
        historic: historic,
        indicator: indicator ?? key ?? '',
        widgetConfig: props
      })
    ]
  }

  const showHistoric = historic !== undefined || widget.config?.showHistoric
  const historicType = historic !== undefined ? historic.type : widget.config?.historicType
  const historicMapper = historic !== undefined
    && historic.mapper !== undefined
    ? historic.mapper
    : widget.config?.historicMapper

  return {
    ...widget,
    title: title || widget.title,
    subTitle: subTitle || widget.subTitle,
    showHelp: helpText !== undefined || widget.helpText !== undefined,
    helpText: helpText || widget.helpText,
    position: position,
    size: size,
    widgetName: name,
    render: render ?? widget.render,
    config: {
      ...widget.config,
      type: widget.config?.type ?? 'Falta definir tipo de widget ' + widgetId,
      id: widgetId,
      showSample: showSample ?? widget.config?.showSample,
      mapper: mapper ?? widget.config?.mapper,
      tooltipFormatter: tooltipFormatter ?? widget.config?.tooltipFormatter,
      // Historic
      showHistoric: showHistoric,
      historicType: historicType,
      historicMapper: historicMapper,
      ...extraConfigs,
      indicators: indicators
    }
  }
}