import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

export const CAFFENIO_CJ_ORDER = {
    // Satisfacción
    10174: 0, // "Disposición a regresar"
    10171: 1, // "Satisfacción"
    10175: 2, // "NPS"
    // Apertura
    10396: 0, // "Personal"
    10371: 1, // "Disposición a regresar"
    10368: 2, // "Satisfacción"
    10372: 3, // "NPS"
  }

export const omoikaneCaffenio: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        //OmoikaneTab.CLUSTERS
    ],
    monthdsCoverage: 2,
    indicators: [
        {
            indicator: 'SATISFACCION_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'APERTURA_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-satisfaccion',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [10601]
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-apertura',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [10252]
            }
        },
        {
            indicator: 'satisfaccion_pregunta_3',
            key: 'satisfaction-csat',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'apertura_pregunta_1',
            key: 'apertura-csat',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'satisfaccion_pregunta_6',
            key: 'satisfaction-nps',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'apertura_pregunta_5',
            key: 'apertura-nps',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'SURVEY_COUNT',
            key: 'survey-count-satisfaccion',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [10601]
            }
        },
        {
            indicator: 'SURVEY_COUNT',
            key: 'survey-count-apertura',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [10252]
            }
        },
    ],
    sentiment: {
        indicators: {
            satisfaccion: 'sentiment-satisfaccion',
            apertura: 'sentiment-apertura'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            satisfaccion: [
                { order: 1, name: 'NPS', key: 'satisfaction-nps', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-csat', widgetType: 'gauge' },
            ],
            apertura: [
                { order: 1, name: 'NPS', key: 'apertura-nps', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'apertura-csat', widgetType: 'gauge' },
            ],
            /*QR: [
                { order: 1, name: 'NPS', key: 'nps-reparacion', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-reparacion', widgetType: 'gauge' },
            ],*/
        }
    },
    clusters: {
        satisfaccion: {
            counters: { indicator: 'survey-count-satisfaccion' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'satisfaccion_pregunta_3',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'satisfaccion_pregunta_6',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [10601]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [10601]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [10601]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [10601]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [10601]
                        }
                    }
                ]
            }
        },
        apertura: {
            counters: { indicator: 'survey-count-apertura' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'apertura_pregunta_1',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'apertura_pregunta_5',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [10252]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [10252]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [10252]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [10252]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [10252]
                        }
                    }
                ]
            }
        }
    },
    customerJourney: {
        satisfaccion: (data: any) => {
            return data['SATISFACCION_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                weight: CAFFENIO_CJ_ORDER[item.groupId] ?? item.groupId,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
            // @ts-ignore
            .asMutable()
            .sort((item1: any, item2: any) => item1.weight - item2.weight )
        },
        apertura: (data: any) => {
            return data['APERTURA_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                weight: CAFFENIO_CJ_ORDER[item.groupId] ?? item.groupId,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
            // @ts-ignore
            .asMutable()
            .sort((item1: any, item2: any) => item1.weight - item2.weight )
        },
    }
}
